import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

const NotFound = props => {
  useEffect(() => {
    navigate('/')
  }, [])

  return <div></div>
}

NotFound.propTypes = {}

export default withPreview(NotFound)
